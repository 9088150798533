var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.state.loaded)?_c('div',{staticClass:"row justify-content-md-center"},[_c('div',{staticClass:"col col-lg-2"}),_c('div',{staticClass:"col-md-auto mt-20"},[_c('rotate-square5')],1),_c('div',{staticClass:"col col-lg-2"})]):_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('form',{staticClass:"form-horizontal",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('div',{staticClass:"form-body"},[_c('h3',{staticClass:"box-title"},[_vm._v("Klasifikasi Surat Dokumen")]),_c('hr',{staticClass:"m-t-0 m-b-40"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 justify-content-md-center"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 pull-left"},[_c('small',{staticClass:"text-muted"},[_vm._v("Nomor Surat")]),_c('h6',[_vm._v("\n                    "+_vm._s(_vm.state.detail.nomor_surat === ""
                        ? "-"
                        : _vm.state.detail.nomor_surat)+"\n                  ")]),_c('small',{staticClass:"text-muted"},[_vm._v("Nomor Kendali")]),_c('h6',[_vm._v("\n                    "+_vm._s(_vm.state.detail.no_opd === "" ? "-" : _vm.state.detail.no_opd)+"\n                  ")])])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-md-8"},[_c('Input',{attrs:{"name":"idsurat","disabled":"","required":"","type":"hidden"},model:{value:(_vm.payload.idsurat),callback:function ($$v) {_vm.$set(_vm.payload, "idsurat", $$v)},expression:"payload.idsurat"}})],1)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"form-group row"},[_c('label',{class:[
                    'control-label',
                    { 'text-right': !_vm.isMobile },
                    'col-md-3' ]},[_vm._v("\n                  Indeks\n                ")]),_c('div',{class:['col-md-8', { invalid: _vm.isInvalidNamaFolder }]},[_c('Multiselect',{attrs:{"label":"text","track-by":"value","placeholder":"Pilih Klasifikasi Sekunder","open-direction":"bottom","options":_vm.dropdownNamaFolder.namafolderList,"multiple":false,"searchable":true,"value":_vm.dropdownNamaFolder.value,"loading":_vm.dropdownNamaFolder.isLoadingNamaFolder,"internal-search":false,"clear-on-select":true,"close-on-select":true,"options-limit":300,"max-height":600,"show-no-results":true,"hide-selected":true},on:{"close":_vm.onTouchNamaFolder,"input":_vm.onChangeNamaFolder,"search-change":_vm.namafolder},model:{value:(_vm.payload.namafolder),callback:function ($$v) {_vm.$set(_vm.payload, "namafolder", $$v)},expression:"payload.namafolder"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("Oops! No Data found.")])]),_c('label',{directives:[{name:"show",rawName:"v-show",value:(_vm.isInvalidNamaFolder),expression:"isInvalidNamaFolder"}],staticClass:"typo__label from__label"},[_vm._v("Must have at least one value")])],1)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{class:['control-label']},[_vm._v("File Tambahan")]),(!_vm.files.length)?_c('h6',[_vm._v(_vm._s(_vm.$t("no_file")))]):_c('table',{staticClass:"table table-stripped"},[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("file")))]),_c('th',[_vm._v(_vm._s(_vm.$t("action")))])]),_vm._l((_vm.files),function(file){return _c('tr',{key:file.filename},[_c('td',[_vm._v("\n                      "+_vm._s(file.filepath !== undefined
                          ? file.filename
                          : file.name)+"\n                    ")]),_c('td',[(file.filepath !== undefined)?_c('span',[_c('a',{staticClass:"btn btn-success text-white",on:{"click":function($event){_vm.download(file.uuid, file.filename)}}},[_c('i',{staticClass:"fa fa-download text-white"})]),_c('a',{staticClass:"btn btn-info text-white",on:{"click":function($event){_vm.prev(file.uuid)}}},[_c('i',{staticClass:"fa fa-eye text-white"})])]):_vm._e(),_c('i',[_c('b',[_vm._v(_vm._s(_vm.$t("not_saved"))+"   ")])]),_c('a',{staticClass:"btn btn-danger text-white",on:{"click":function($event){_vm.removeAttachment(file.uuid)}}},[_c('i',{staticClass:"fa fa-trash-o text-white"})])])])})],2),_c('Gallery',{attrs:{"uploader":_vm.uploader}})],1)])])]),_c('hr'),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-offset-3 col-md-9"},[_c('button',{staticClass:"btn btn-success",attrs:{"type":"submit","title":"Submit"}},[_vm._v("\n                    Submit")]),_vm._v(" \n                  "),_c('button',{staticClass:"btn btn-inverse",attrs:{"type":"button","title":"Cancel"},on:{"click":function($event){_vm.goBack()}}},[_vm._v("\n                    Cancel\n                  ")])])])]),_c('div',{staticClass:"col-md-6"})])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }