<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <form @submit.prevent="submit" class="form-horizontal">
          <div class="form-body">
            <h3 class="box-title">Klasifikasi Surat Dokumen</h3>
            <hr class="m-t-0 m-b-40" />

            <div class="row">
              <div class="col-md-12 justify-content-md-center">
                <div class="row">
                  <div class="col-md-6 pull-left">
                    <!-- <small class="text-muted">Agenda Nomor</small>
                    <h6>
                      {{
                        state.detail.agenda_number === ""
                          ? "-"
                          : state.detail.agenda_number
                      }}
                    </h6> -->
                    <small class="text-muted">Nomor Surat</small>
                    <h6>
                      {{
                        state.detail.nomor_surat === ""
                          ? "-"
                          : state.detail.nomor_surat
                      }}
                    </h6>
                    <small class="text-muted">Nomor Kendali</small>
                    <h6>
                      {{
                        state.detail.no_opd === "" ? "-" : state.detail.no_opd
                      }}
                    </h6>
                    <!-- <small class="text-muted">Perihal</small>
                    <h6>
                      {{
                        state.detail.subject === "" ? "-" : state.detail.subject
                      }}
                    </h6> -->
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <div class="col-md-8">
                    <Input
                      v-model="payload.idsurat"
                      name="idsurat"
                      disabled
                      required
                      type="hidden"
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- {{ state.detail.dispo }} -->

            <!-- <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label
                    :class="[
                      'control-label',
                      { 'text-right': !isMobile },
                      'col-md-3',
                    ]"
                  >
                    Klasifikasi
                  </label>
                  <div class="col-md-8">
                    <treeselect
                      v-model="payload.klasifikasi"
                      :options="indukklasifikasi"
                    />
                  </div>
                </div>
              </div>
            </div> -->

            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label
                    :class="[
                      'control-label',
                      { 'text-right': !isMobile },
                      'col-md-3',
                    ]"
                  >
                    Indeks
                  </label>
                  <div :class="['col-md-8', { invalid: isInvalidNamaFolder }]">
                    <Multiselect
                      v-model="payload.namafolder"
                      label="text"
                      track-by="value"
                      placeholder="Pilih Klasifikasi Sekunder"
                      open-direction="bottom"
                      :options="dropdownNamaFolder.namafolderList"
                      :multiple="false"
                      :searchable="true"
                      :value="dropdownNamaFolder.value"
                      :loading="dropdownNamaFolder.isLoadingNamaFolder"
                      :internal-search="false"
                      :clear-on-select="true"
                      :close-on-select="true"
                      :options-limit="300"
                      :max-height="600"
                      :show-no-results="true"
                      :hide-selected="true"
                      @close="onTouchNamaFolder"
                      @input="onChangeNamaFolder"
                      @search-change="namafolder"
                    >
                      <span slot="noResult">Oops! No Data found.</span>
                    </Multiselect>
                    <label
                      class="typo__label from__label"
                      v-show="isInvalidNamaFolder"
                      >Must have at least one value</label
                    >
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label class="control-label text-right col-md-3"> Tag </label>
                  <div class="col-md-8">
                    <Input
                      v-model="payload.tag"
                      class="form-group"
                      name="tag"
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div> -->

            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label :class="['control-label']">File Tambahan</label>
                  <h6 v-if="!files.length">{{ $t("no_file") }}</h6>
                  <table v-else class="table table-stripped">
                    <tr>
                      <th>{{ $t("file") }}</th>
                      <th>{{ $t("action") }}</th>
                    </tr>
                    <tr v-for="file in files" v-bind:key="file.filename">
                      <td>
                        {{
                          file.filepath !== undefined
                            ? file.filename
                            : file.name
                        }}
                      </td>
                      <td>
                        <span v-if="file.filepath !== undefined">
                          <a
                            class="btn btn-success text-white"
                            @click="download(file.uuid, file.filename)"
                            ><i class="fa fa-download text-white"></i>
                          </a>
                          <a
                            class="btn btn-info text-white"
                            @click="prev(file.uuid)"
                            ><i class="fa fa-eye text-white"></i>
                          </a>
                        </span>
                        <i
                          ><b>{{ $t("not_saved") }} &nbsp;&nbsp;</b></i
                        >
                        <a
                          class="btn btn-danger text-white"
                          @click="removeAttachment(file.uuid)"
                        >
                          <i class="fa fa-trash-o text-white"></i>
                        </a>
                      </td>
                    </tr>
                  </table>
                  <Gallery :uploader="uploader" />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="form-actions">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-offset-3 col-md-9">
                    <button
                      type="submit"
                      class="btn btn-success"
                      title="Submit"
                    >
                      Submit</button
                    >&nbsp;
                    <button
                      type="button"
                      @click="goBack()"
                      class="btn btn-inverse"
                      title="Cancel"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-6"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- {{ this.state.detail.asberkas }} -->
  </div>
</template>
<script src="https://unpkg.com/@johmun/vue-tags-input/dist/vue-tags-input.js"></script>
<script>
import { RotateSquare5 } from "vue-loading-spinner";
import Multiselect from "vue-multiselect";
import VueTagsInput from "@johmun/vue-tags-input";
import Upload from "@/components/input/upload.vue";
import Input from "@/components/input/input";
import axios from "axios";

export default {
  components: {
    Input,
    RotateSquare5,
    Multiselect,
    Upload,
  },
  computed: {
    editMode() {
      return !!this.$route.params.id;
    },
    isInvalidNamaFolder() {
      return (
        this.dropdownNamaFolder.isTouched &&
        this.dropdownNamaFolder.value.length === 0
      );
    },
    // items() {
    //   var data = [];
    //   if (this.$store.state.pemberkasan.items) {
    //     for (
    //       let index = 0;
    //       index < this.$store.state.pemberkasan.items.items.length;
    //       index++
    //     ) {
    //       let element = this.$store.state.pemberkasan.items.items[index];
    //       data[index] = {
    //         id: element.id,
    //         agenda_number: element.agenda_number,
    //         nomor_surat: element.nomorsurat,
    //         subject: element.subject,
    //         sifat: element.sifat,
    //         tanggal_diterima: element.tanggal_diterima,
    //         redaksi: element.redaksi,
    //         asal_surat: element.asalsurat,
    //         confirmation: element.confirmation,
    //         confirm: element.confirm,
    //         close: element.close
    //       };
    //     }
    //   }
    //   return data;
    // },
    state() {
      return this.$store.state.pemberkasan;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
    isinvalidTipe() {
      return this.isTouched && this.payload.valueTipe.length === 0;
    },
  },
  data() {
    const uploader = this.fineUploader(this, "surat_diberkaskan");
    return {
      // tag: "",
      // tags: [],
      value: null,
      idsurat: this.$route.params.id,
      suratid: 0,
      uploader,
      payload: {
        id_surat: this.$route.params.id,
        surat: this.$route.params.id,
        // indukklasifikasi: [],
        // klasifikasi: [],
        namafolder: [],
        // tag: "",
        file: "",
      },
      dropdownNamaFolder: {
        isTouch: false,
        namafolderList: [],
        value: [],
        isLoadingNamaFolder: false,
      },
      isDisabled: false,
      optionTipe: [],
      // indukklasifikasi: [],
      myFile: [],
      files: [],
    };
  },
  async mounted() {
    this.initialize();
    this.getAllReference();
    this.treeKlasifikasi();
    this.detailLoad();
  },

  methods: {
    async initialize() {
      try {
        if (this.editMode) {
          axios
            .get(`/pemberkasan/${this.$route.params.id}`)
            .then((response) => {
              this.payload.idsurat = this.$route.params.id;
              const state = {
                loaded: true,
              };
              this.$store.commit("clasificationdoc/STATE", state);
              this.edit(response.data.data);
            });
        } else {
          this.$store.dispatch("clasificationdoc/onAdd");
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    detailLoad() {
      const state = {
        loaded: false,
      };
      const user = localStorage.getItem("user");
      this.$store.commit("pemberkasan/STATE", state);
      this.$store.dispatch(
        "pemberkasan/getPemberkasanById",
        this.$route.params
      );
    },
    treeKlasifikasi() {
      axios.get(`/mail_code/get_list`).then((res) => {
        this.indukklasifikasi = res.data.data;
      });
    },
    // treeKlasifikasi(){
    //   axios.get(`/clasificationdoc/get_list_klasifikasi`).then(res=>{
    //     this.indukklasifikasi = res.data.data;
    //   })
    // },
    removeAttachment(uuid) {
      let vm = this;
      let files = vm.uploader.methods.getUploads();
      files.map((element) => {
        if (element.uuid == uuid) {
          let folder;
          vm.files.map((ele) => {
            if (ele.uuid == uuid) {
              folder = ele.folder;
            }
          });
          vm.uploader.methods.setDeleteFileParams(
            { folder: folder },
            element.id
          );
          vm.uploader.methods.deleteFile(element.id);
        }
      });
      vm.files = vm.files.filter((element) => {
        return element.uuid != uuid;
      });
    },
    // load options for the v-treeselect component

    getAllReference() {
      var query = "";
      var mvunit = this.state.detail.idunit;
      this.namafolder(query, mvunit);
    },
    indukklasifikasi() {
      this.payload.klasifikasi = [];
    },
    _onInputExtendedCost: function ($event) {
      this.suratid = parseInt($event.target.value);
      // Go update other inputs here
    },
    edit(data) {
      this.payload = {
        idsurat: this.$route.params.id,
        namafolder: data.nama_folder,
        // tag: data.tag,
        // files: data.files,
      };
      // this.files = data.files;
    },
    goBack() {
      this.$store.dispatch("pemberkasan/onCancel");
    },
    onTouchNamaFolder() {
      this.dropdownNamaFolder.isTouched = true;
    },
    onTouch() {
      this.isTouched = true;
      this.isTouchedInduk = true;
      this.isTouchedTujuan = true;
    },
    onChangeNamaFolder(value) {
      this.dropdownNamaFolder.value = value;
    },
    onChangeTipe(value) {
      this.payload.valueTipe = value;
    },
    namafolder(query, mvunit) {
      const mv = this;
      mv.dropdownNamaFolder.isLoadingNamaFolder = true;
      // const mvunit = mv.state.detail.idunit;
      axios
        // .get(`daftarfolder/list?s=${query}&u=${mvunit}`)
        // payload.idsurat
        .get(`daftarfolder/list_folder?s=${query}&u=${this.$route.params.unit}`)
        // .get(`daftarfolder/list?s=${query}&u=${this.payload.idsurat}`)
        .then((res) => {
          mv.dropdownNamaFolder.namafolderList = res.data.items;
          mv.dropdownNamaFolder.isLoadingNamaFolder = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            store.commit("auth/LOGOUT");
          }
        });
    },
    submit() {
      const payload = {
        id_surat: this.$route.params.id,
        // klasifikasi: this.payload.klasifikasi,
        asal_berkas: this.state.detail.asal,
        dispo: this.state.detail.dispo,
        nama_folder: parseInt(this.payload.namafolder.value),
        // tag: this.payload.tag,
        files: this.files,
      };
      // let newPayload = new FormData();
      //     newPayload.append('files',this.files);
      //     newPayload.append('id_surat', this.$route.params.id);
      //     newPayload.append('klasifikasi', this.payload.klasifikasi);
      //     newPayload.append('nama_folder', parseInt(this.payload.namafolder.value));
      //     newPayload.append('tag', this.tag);
      // const data = JSON.stringify(this.payload);
      // const data = newPayload;
      const data = payload;
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.editMode) {
            this.$store.dispatch("pemberkasan/submitBerkas", data);
          } else {
            this.$store.dispatch("pemberkasan/submitBerkas", data);
          }
        }
      });
    },
  },
  watch: {
    $route: function (search) {
      this.detailLoad();
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
.normal-background {
  background-color: #fff !important;
}
</style>

